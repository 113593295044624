import "./channel-selection-modal.css";
import Modal from "../../components/modal/Modal";
import {useModalsStore} from "../../stores/modalsStore";
import {IChannelInfo, useChannelStore, DChannel} from "../../stores/channelStore";
import {useEffect, useState} from "react";
import API from "../../API";
import {IPivotUserChannels} from "../../DbInterfaces";
import {useUserStore} from "../../stores/userStore";

const ChannelSelectionModal = () => {
    const {currUser} = useUserStore();
    const {setCurrChannel} = useChannelStore();
    const {setChannelSelection} = useModalsStore();

    const [channels, setChannels] = useState<IChannelInfo[]>([DChannel]);

    const selectChannel = (channel: IChannelInfo) => {
        setCurrChannel(channel);
        close();
    };

    const close = () => {
        setChannelSelection(false);
    };

    const prepareChannelList = () => {
        API.users.get.channels(currUser!.id)
            .then((response) => {
                const newChannelsList: IChannelInfo[] = response.data.map((row: any): IChannelInfo => {
                    const pivotUserChannel: IPivotUserChannels = row;
                    return {
                        id: pivotUserChannel.id,
                        name: pivotUserChannel.name,
                        actor_name: pivotUserChannel.pivot.actor_name ?? currUser!.name,
                        actor_gender: pivotUserChannel.pivot.actor_name ? pivotUserChannel.pivot.actor_gender : currUser!.gender,
                        is_author: pivotUserChannel.pivot.is_author,
                        is_storyteller: pivotUserChannel.pivot.is_storyteller,
                        is_owner: pivotUserChannel.pivot.is_owner,
                        last_seen_at: pivotUserChannel.pivot.last_seen_at
                    };
                }).sort((a: IChannelInfo, b: IChannelInfo) => {
                    return a.id - b.id;
                });

                setChannels(newChannelsList)
            })
            .catch((error) => {
                return [];
            });
    };

    useEffect(() => {
        prepareChannelList();
    }, []);

    return (
        <Modal
            id={'channel-selection-modal'}
            title={"Selezione del canale"}
            closeCaption={"Chiudi"}
            onClose={() => {
                close();
            }}
        >
            {channels.map((channel) => {
                return (
                    <div key={channel.id} className={"element"} onClick={() => {
                        selectChannel(channel);
                    }}>
                        {channel.name}
                    </div>
                );
            })}
        </Modal>
    );
};

export default ChannelSelectionModal;
