export type TColorTheme = "Light"|"Ivory"|"Peach"|"Night"|"Chocolate"|"Dark";

export interface IThemeClasses {
    window: string,
    chat: string,
    title: string,
    active: string,
    text: string
}

class ColorTheme {

    static getWindowColorClassName(colorTheme: TColorTheme){
        return `bgGradient${colorTheme}`;
    }

    static getChatBgColorClassName(colorTheme: TColorTheme){
        return `bg${colorTheme}`;
    }

    static getTitleColorClassName(colorTheme: TColorTheme){
        return `title${colorTheme}`;
    }

    static getActiveColorClassName(colorTheme: TColorTheme){
        return `active${colorTheme}`;
    }

    static getTextColorClassName(colorTheme: TColorTheme){
        return `text${colorTheme}`;
    }

    static getClasses(colorTheme: TColorTheme): IThemeClasses {
        return {
            window: ColorTheme.getWindowColorClassName(colorTheme),
            chat: ColorTheme.getChatBgColorClassName(colorTheme),
            title: ColorTheme.getTitleColorClassName(colorTheme),
            active: ColorTheme.getActiveColorClassName(colorTheme),
            text: ColorTheme.getTextColorClassName(colorTheme),
        };
    }

}

export default ColorTheme;