import LSSettings from "./LSSHandler";
import API from "./API";
import jwt_decode from "jwt-decode";

class AuthToken {

    static decodedToken(): any {
        const authToken: string = LSSettings.read("authToken", "");
        return jwt_decode<any>(authToken);
    }

    static userId(): number {
        const decodedToken = this.decodedToken();
        return decodedToken !== null ? decodedToken.id : 0;
    }

    static userName(): string {
        const decodedToken = this.decodedToken();
        return decodedToken !== null ? decodedToken.name : "";
    }

    static exists(): boolean {
        const authToken: string = LSSettings.read("authToken", "");
        return authToken !== "";
    }

    static expiration(): number {
        const decodedToken = this.decodedToken();
        let exp: number = 0;
        if (decodedToken !== null && decodedToken['exp'] !== undefined) exp = decodedToken['exp'];
        return exp;
    }

    static isAboutToExpire(): boolean {
        const now: number = Math.floor(Date.now()/1000);
        const exp: number = AuthToken.expiration();
        const diff: number = exp - now;
        return diff <= 60;
    }

    static isUserAdmin(): boolean {
        const decodedToken = this.decodedToken();
        return decodedToken !== null ? decodedToken.is_admin : false;
    }

    static async isValid(): Promise<boolean> {
        return API.auth.get.verify()
        .then((response)=>{
            return true;
        })
        .catch((error)=>{
            return false;
        });
    }

}

export default AuthToken;
