import {useRef, useState} from "react";
import PostType from "../../models/PostType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useThemeStore} from "../../stores/themeStore";
import ColorTheme from "../../ColorTheme";

interface IProps {
    default: PostType
    options: PostType[]
    onChange: (postType: PostType) => void
}

const PostTypeSelector = (props: IProps) => {
    const [current, setCurrent] = useState<PostType>(props.default);
    const [show, setShow] = useState<boolean>(false);
    const selectRef = useRef<HTMLSelectElement>(null);

    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    const handleSelectorElementClick = (value: PostType) => {
        setCurrent(value);
        setShow(false);

        if (props.onChange === undefined) return;
        props.onChange(value);
    };

    return (
        <div className={"post-type-selector"}>
            <PostTypeElement postType={current} onClick={(value) => {
                setShow(!show);
            }}/>
            {show && (
                <div className={`selector ${theme.window}`}>
                    {props.options.map((item, index) => {
                        return (
                            <PostTypeElement
                                postType={item} key={index}
                                onClick={(value) => {
                                    handleSelectorElementClick(value);
                                }}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

interface IPostTypeElementProps {
    postType: PostType
    onClick: (value: PostType) => void
}

const PostTypeElement = (props: IPostTypeElementProps) => {
    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);
    const [hover, setHover] = useState<boolean>(false);

    return (
        <div
            className={`post-type ${hover ? theme.active : theme.text}`}
            onMouseOver={() => {
                setHover(true);
            }}
            onMouseOut={() => {
                setHover(false);
            }}
            onClick={() => {
                if (props.onClick === undefined) return;
                props.onClick(props.postType);
            }}
        >
            <table>
                <tbody>
                <tr>
                    <td width={15}>
                        <FontAwesomeIcon icon={props.postType.icon}/>
                    </td>
                    <td>
                        <span>{props.postType.name}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PostTypeSelector;