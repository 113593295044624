export interface IChannel {
    id: number
    name: string
    created_at: string
    updated_at: string
}

export interface IPivotUserChannels {
    id: number
    name: string
    created_at: string
    updated_at: string
    pivot: IChannelUser
}

export interface IPost {
    id?: number
    channel_id: number
    user_id: number
    content_type: string
    actor_name: string
    actor_gender: string
    content: string
    created_at: string
    updated_at: string
}

export interface IUser {
    id: number,
    name: string,
    email: string,
    password_encrypted: string,
    salt: string,
    is_admin: boolean,
    gender: string,
    profile_image_url?: string,
    created_at?: string,
    updated_at?: string
}

export interface IChannelUser {
    id: number
    actor_gender: string
    actor_name: string
    channel_id: number
    content: string
    content_type: string
    created_at: string
    updated_at: string
    user_id: number
    is_author: boolean
    is_storyteller: boolean
    is_owner: boolean
    last_seen_at: string
}

export interface IChapter {
    id: number
    channel_id: number
    title: string
    begin_at: string
    end_at: string
    created_at: string
    updated_at: string
}

// Default values

export const DUser: IUser = {
    id: 0,
    name: '',
    email: '',
    password_encrypted: '',
    salt: '',
    gender: 'N',
    profile_image_url: undefined,
    is_admin: false,
    created_at: '',
    updated_at: ''
};