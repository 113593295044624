import create from 'zustand';
import ColorTheme, {IThemeClasses, TColorTheme} from "../ColorTheme";
import LSSettings from "../LSSHandler";

export interface IThemeStore {
    colorTheme: TColorTheme
    getThemeClasses: () => IThemeClasses
    setColorTheme: (colorTheme: TColorTheme) => void
}

export const useThemeStore = create<IThemeStore>((set) => ({
    colorTheme: LSSettings.read("colorTheme", "Ivory"),
    getThemeClasses: () => {
        const themeCode = LSSettings.read("colorTheme", "Ivory");
        return ColorTheme.getClasses(themeCode);
    },
    setColorTheme: (newColorTheme: TColorTheme) => {
        LSSettings.write("colorTheme", newColorTheme);
        return set((state) => ({
            colorTheme: newColorTheme
        }));
    }
}));
