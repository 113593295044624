import "./modal.css";
import {ReactNode} from "react";
import {useThemeStore} from "../../stores/themeStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";

interface IModalProps {
    id: string
    title?: string
    children?: ReactNode
    width?: string
    cancelCaption?: string
    confirmCaption?: string
    closeCaption?: string
    onCancel?: () => void
    onConfirm?: () => void
    onClose?: () => void
}

const Modal = (props: IModalProps) => {
    const {getThemeClasses} = useThemeStore();

    const handleCancellation = () => {
        if (props.onCancel !== undefined) props.onCancel();
    };

    const handleClosing = () => {
        if (props.onClose !== undefined) props.onClose();
    };

    const handleConfirmation = () => {
        if (props.onConfirm !== undefined) props.onConfirm();
    };

    const themeClasses = getThemeClasses();
    const windowWidth = (props.width !== undefined ? props.width : "auto");
    return (
        <div className={"component--modal"}>
            <div className={`window ${themeClasses.window}`} style={{width: windowWidth}} id={props.id}>
                {props.title !== undefined && (
                    <>
                        <div className={`title ${themeClasses.title}`}>
                            <h4>{props.title}</h4>
                        </div>
                        <div className={"separator"}>
                            <hr/>
                        </div>
                    </>
                )}
                <div className={`body ${themeClasses.text}`}>
                    {props.children}
                </div>
                {(props.onCancel !== undefined || props.onConfirm !== undefined || props.onClose !== undefined) && (
                    <>
                        <div className={"separator"}>
                            <hr/>
                        </div>
                        <div className={"commands"}>
                            {props.onCancel !== undefined && (
                                <button className={"cancelButton"} onClick={() => {
                                    handleCancellation();
                                }}>
                                    <FontAwesomeIcon icon={faCancel}/>
                                    <span>{props.cancelCaption !== undefined ? props.cancelCaption : "Cancel"}</span>
                                </button>
                            )}
                            {props.onClose !== undefined && (
                                <button className={"closeButton"} onClick={() => {
                                    handleClosing();
                                }}>
                                    <FontAwesomeIcon icon={faXmark}/>
                                    <span>{props.closeCaption !== undefined ? props.closeCaption : "Close"}</span>
                                </button>
                            )}
                            {props.onConfirm !== undefined && (
                                <button className={"confirmButton"} onClick={() => {
                                    handleConfirmation();
                                }}>
                                    <FontAwesomeIcon icon={faCheck}/>
                                    <span>{props.confirmCaption !== undefined ? props.confirmCaption : "Confirm"}</span>
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Modal;
