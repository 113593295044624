import PostType from "../models/PostType";
import {faCommentDots} from "@fortawesome/free-solid-svg-icons/faCommentDots";
import {faChessPawn} from "@fortawesome/free-solid-svg-icons/faChessPawn";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faStickyNote} from "@fortawesome/free-solid-svg-icons/faStickyNote";
import {faBookmark} from "@fortawesome/free-solid-svg-icons/faBookmark";
import {faParagraph} from "@fortawesome/free-solid-svg-icons/faParagraph";
import {faFileImage} from "@fortawesome/free-solid-svg-icons/faFileImage";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

const dialog = new PostType({id: "dialog", name: "Dialogo", icon: faCommentDots});
const action = new PostType({id: "action", name: "Azione", icon: faChessPawn});
const note = new PostType({id: "note", name: "Nota", icon: faStickyNote});
const narration = new PostType({id: "narration", name: "Narrazione", icon: faBook});
const bookmark = new PostType({id: "bookmark", name: "Segnalibro", icon: faBookmark});
const chapter = new PostType({id: "chapter", name: "Capitolo", icon: faParagraph});
const picture = new PostType({id: "picture", name: "Immagine", icon: faFileImage});
const link = new PostType({id: "link", name: "Collegamento", icon: faExternalLinkAlt});

const PostTypeList: PostType[] = [
    dialog,
    action,
    note,
    narration,
    bookmark,
    chapter,
    picture,
    link
];

export default PostTypeList;