import "./wiget.css";
import React, {useState} from "react";
import {useThemeStore} from "../../stores/themeStore";

export interface IWidgetProps {
    title?: string
    children: React.ReactNode
    show?: boolean
}

const Widget = (props: IWidgetProps) => {
    const {getThemeClasses} = useThemeStore();
    const theme = getThemeClasses();

    const [show, setShow] = useState<boolean>(props.show ?? false);

    return (
        <div className={`component--widget ${theme.window}`}>
            <div className={`title ${theme.window}`}>
                {props.title}
                <input type={"checkbox"} style={{float: "right"}} checked={show} onChange={() => {
                    setShow(!show);
                }}/>
            </div>
            <div className={`body ${theme.text}`} style={{display: show ? "block" : "none"}}>
                {props.children}
            </div>
        </div>
    );
};

export default Widget;