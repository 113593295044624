import './chat-body.css';
import ColorTheme from "../../ColorTheme";
import {IPost} from "../../DbInterfaces";
import ChatPost from "../chat-post/ChatPost";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";
import {useThemeStore} from "../../stores/themeStore";

interface IProps {
    posts: IPost[]
    onChatUpdated: (byOthers: boolean, firstTime: boolean) => void
}

const ChatBody = (props: IProps) => {
    // ----- stores
    const {chatContentFilters} = useChatOptionsStore();
    const {colorTheme} = useThemeStore();

    // ----- state
    const theme = ColorTheme.getClasses(colorTheme);

    return (
        <div className={`component--chat-body ${theme.chat}`}>
            {
                props.posts
                    // filtro - tipi di contenuto
                    .filter((post: IPost) => {
                        const contentType: string = post.content_type;
                        if (contentType === "dialog") return chatContentFilters.narration;
                        if (contentType === "action") return chatContentFilters.narration;
                        if (contentType === "narration") return chatContentFilters.narration;
                        if (contentType === "image") return chatContentFilters.images;
                        if (contentType === "note") return chatContentFilters.notes;
                        if (contentType === "bookmark") return chatContentFilters.bookmarks;
                        if (contentType === "chapter_break") return chatContentFilters.Chapters;
                        if (contentType === "system") return chatContentFilters.system;
                        return true;
                    })
                    // ordinamento - cronologia
                    .sort((a: IPost, b: IPost) => {
                        if (a.created_at > b.created_at) return 1;
                        else return -1;
                    })
                    // rendering
                    .map((post: IPost, index: number) => {
                        return (
                            <ChatPost key={index} post={post}/>
                        );
                    })
            }
        </div>
    );
};

export default ChatBody;
