import axios, {AxiosRequestConfig} from "axios";
import LSSettings from "./LSSHandler";
import CONFIG from "./Config";

class APIHelper {

    static basicRequestConfig(params?: any){
        let baseConfig: AxiosRequestConfig = {};
        const authToken = LSSettings.read('authToken', "");
        if (authToken !== "") baseConfig.headers = {
            'Authorization': `Bearer ${authToken}`
        };
        if (params !== undefined) baseConfig.params = params;

        return baseConfig;
    }

    static get(endpoint: string, params?: any){
        const requestConfig = APIHelper.basicRequestConfig(params);
        return axios.get(CONFIG.ApiBaseUrl + endpoint,  requestConfig);
    }

    static post(endpoint: string, data: any, params?: any){
        const requestConfig = APIHelper.basicRequestConfig(params);
        data._method = 'POST';
        return axios.post(CONFIG.ApiBaseUrl + endpoint, data, requestConfig);
    }

    static delete(endpoint: string, params?: any){
        const requestConfig = APIHelper.basicRequestConfig(params);
        const data: any = {
            _method: 'DELETE'
        };
        return axios.post(CONFIG.ApiBaseUrl + endpoint, data, requestConfig);
    }

    static put(endpoint: string, data: any, params?: any){
        const requestConfig = APIHelper.basicRequestConfig(params);
        data._method = 'PUT';
        return axios.post(CONFIG.ApiBaseUrl + endpoint, data, requestConfig);
    }

    static patch(endpoint: string, data: any, params?: any){
        const requestConfig = APIHelper.basicRequestConfig(params);
        data._method = 'PATCH';
        return axios.post(CONFIG.ApiBaseUrl + endpoint, data, requestConfig);
    }

}

export default APIHelper;