import {useRef, useState} from "react";
import {useThemeStore} from "../../../stores/themeStore";
import ColorTheme from "../../../ColorTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightAndDownLeftFromCenter} from "@fortawesome/free-solid-svg-icons/faUpRightAndDownLeftFromCenter";
import {faExpand} from "@fortawesome/free-solid-svg-icons/faExpand";
import {faFont} from "@fortawesome/free-solid-svg-icons/faFont";
import LSSettings from "../../../LSSHandler";

const ContentOnly = (props: { onSend?: (content: string) => void }) => {
    const step: number = 75;
    const maxSteps: number = 5;
    const [height, setHeight] = useState<number>(step);
    const [fontSize, setFontSize] = useState<number>(14);
    const contentRef = useRef<HTMLTextAreaElement>(null);

    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    const handleSizeButtonClick = () => {
        if (height >= (step * maxSteps)) {
            setHeight(step);
            return;
        }

        setHeight(height + step);
    };

    const handleExpandButtonClick = () => {
        if (height > step) {
            setHeight(step);
            return;
        }

        setHeight(step * maxSteps);
    };

    const handleFontSizeButtonClick = () => {
        if (fontSize >= 26) {
            setFontSize(14);
            return;
        }

        setFontSize(fontSize + 4);
    };

    const handleSave = () => {
        if (contentRef === undefined) return;
        LSSettings.write("contentBackup", contentRef!.current!.value);
    };

    const handleLoad = () => {
        if (contentRef === undefined) return;
        contentRef!.current!.value = LSSettings.read("contentBackup", "");
    };

    return (
        <div className={"content-form content-only"}>
            <textarea
                ref={contentRef} className={theme.chat}
                style={{height: height, fontSize: fontSize}}
            />
            <div className={"top-commands"}>
                <div>
                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} onClick={() => {
                        handleSizeButtonClick();
                    }}/>
                    <FontAwesomeIcon icon={faExpand} onClick={() => {
                        handleExpandButtonClick();
                    }}/>
                    <FontAwesomeIcon icon={faFont} onClick={() => {
                        handleFontSizeButtonClick();
                    }}/>
                </div>
                <button className={`command-button ${theme.window}`} onClick={() => {
                    handleSave();
                }}>
                    Salva
                </button>
                <button className={`command-button ${theme.window}`} onClick={() => {
                    if (contentRef === undefined) return;
                    contentRef!.current!.value = "";
                }}>
                    Reset
                </button>
                <button className={`command-button ${theme.window}`} onClick={() => {
                    handleLoad();
                }}>
                    Carica
                </button>
                <button className={`command-button ${theme.window}`} onClick={() => {
                    if (props.onSend === undefined) return;
                    if (contentRef === null) return;
                    props.onSend(contentRef!.current!.value);
                }}>
                    Invia
                </button>
            </div>
        </div>
    );
};

export default ContentOnly;