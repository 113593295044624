import "./chat-commands.css";
import PostTypeSelector from "./PostTypeSelector";
import PostTypeList from "../../lists/PostTypeList";
import PostType from "../../models/PostType";
import {useState} from "react";
import {useThemeStore} from "../../stores/themeStore";
import ColorTheme from "../../ColorTheme";
import ContentOnly from "./post-type-forms/ContentOnly";
import TitleOnly from "./post-type-forms/TitleOnly";
import Picture from "./post-type-forms/Picture";
import Link from "./post-type-forms/Link";
import {forceScroll} from "../chat/Chat";
import {IPost} from "../../DbInterfaces";
import {useChannelStore} from "../../stores/channelStore";
import {useUserStore} from "../../stores/userStore";

const ChatCommands = (props: { onSend: (post: IPost) => void }) => {
    const {currUser} = useUserStore();
    const {currChannel} = useChannelStore();
    const [postType, setPostType] = useState<PostType>(PostTypeList[0]);
    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    const handlePostTypeChange = (value: PostType) => {
        setPostType(value);
    };

    const handleSend = (content: string) => {
        const newPost: IPost = {
            id: undefined,
            user_id: currUser?.id ?? 0,
            channel_id: currChannel?.id ?? 0,
            content_type: postType.id,
            content: content,
            actor_name: currChannel.actor_name,
            actor_gender: currChannel.actor_gender,
            created_at: 'NOW',
            updated_at: 'NOW'
        };

        props.onSend(newPost);
    };

    return (
        <div className={`chat-commands ${theme.window}`}>
            <div className={"top-commands"}>
                <PostTypeSelector
                    default={PostTypeList[0]} options={PostTypeList}
                    onChange={(value) => {
                        handlePostTypeChange(value);
                    }}
                />
                <button className={`command-button ${theme.window}`} onClick={() => {
                    forceScroll();
                }}>
                    Scorri in basso
                </button>
            </div>
            {["dialog", "action", "note", "narration"].includes(postType.id) && (
                <ContentOnly onSend={(content) => {
                    handleSend(content);
                }}/>
            )}
            {["bookmark", "chapter"].includes(postType.id) && (
                <TitleOnly onSend={(content) => {
                    handleSend(content);
                }}/>
            )}
            {["picture"].includes(postType.id) && (
                <Picture onSend={(content: string) => {
                    handleSend(content);
                }}/>
            )}
            {["link"].includes(postType.id) && (
                <Link onSend={(content: string) => {
                    handleSend(content);
                }}/>
            )}
        </div>
    );
};

export default ChatCommands;