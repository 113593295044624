import "./user-bar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    IconDefinition, faBars, faGlobe, faSheetPlastic, faPalette, faRightFromBracket, faBell, faAnglesDown
} from "@fortawesome/free-solid-svg-icons";
import React, {ReactNode, useEffect, useState} from "react";
import {useChannelStore} from "../../stores/channelStore";
import {useThemeStore} from "../../stores/themeStore";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";
import {useUserStore} from "../../stores/userStore";
import {useModalsStore} from "../../stores/modalsStore";

const AVATARS: { [key: number]: string } = {
    0: "bear",
    1: "cat",
    2: "deer",
    3: "dog1",
    4: "dog2",
    5: "donkey",
    6: "elephant",
    7: "fox",
    8: "monkey",
    9: "mouse",
    10: "otter",
    11: "panda",
    12: "pig",
    13: "rabbit",
    14: "raccoon",
    15: "zebra"
};

const UserBar = () => {
    const {currUser} = useUserStore();
    const {currChannel} = useChannelStore();
    const {
        autoScroll,
        sounds,
        leftMenu,
        rightMenu,
        setAutoScroll,
        setSounds,
        setLeftMenu,
        setRightMenu
    } = useChatOptionsStore();
    const {getThemeClasses} = useThemeStore();
    const {logout} = useUserStore();
    const {setChannelSelection, setThemeSelection} = useModalsStore();

    const defaultProfileImageUrl = (): string => {
        const rand: number = Math.floor(Math.random() * 16);
        return `${process.env.PUBLIC_URL}/avatars/${AVATARS[rand]}.jpg`;
    };

    const [profileUrl, setProfileUrl] = useState<string>(defaultProfileImageUrl);

    useEffect(() => {
        if (currUser!.profile_image_url !== null && currUser!.profile_image_url !== undefined) {
            setProfileUrl(currUser!.profile_image_url)
        }
    }, [currUser]);

    const callChannelSelectionModal = () => {
        setChannelSelection(true);
    };

    const callThemeSelectionModal = () => {
        setThemeSelection(true);
    };

    const toggleLeftMenu = () => {
        setLeftMenu(!leftMenu);
    };

    const toggleRightMenu = () => {
        setRightMenu(!rightMenu);
    };

    const toggleAutoScroll = () => {
        setAutoScroll(!autoScroll)
    };

    const toggleSounds = () => {
        setSounds(!sounds);
    };

    const themeClasses = getThemeClasses();
    return (
        <div className={`component--user-bar ${themeClasses.window}`}>
            <UserBarSwitch icon={faBars} title={"Menu laterale"} active={leftMenu} onClick={() => {
                toggleLeftMenu();
            }}/>
            <UserBarGroup>
                <UserBarButton icon={faGlobe} title={"Selezione del canale"} onClick={() => {
                    callChannelSelectionModal();
                }}/>
                <UserBarText>{currChannel.name}</UserBarText>
            </UserBarGroup>
            <UserBarGroup>
                <UserBarSwitch icon={faSheetPlastic} title={"Scheda del personaggio"} active={rightMenu}
                               onClick={() => {
                                   toggleRightMenu();
                               }}/>
                <UserBarSwitch icon={faAnglesDown} title={"Autoscorrimento"} active={autoScroll} onClick={() => {
                    toggleAutoScroll();
                }}/>
                <UserBarSwitch icon={faBell} title={"Notifiche sonore"} active={sounds} onClick={() => {
                    toggleSounds();
                }}/>
                <UserBarButton icon={faPalette} title={"Selezione del tema"} onClick={() => {
                    callThemeSelectionModal();
                }}/>
                <div className={"profile"}>
                    <span className={"username"}>{currUser!.name}</span>
                    <div className={"image"} style={{'backgroundImage': `url(${profileUrl})`}}/>
                </div>
                <UserBarButton icon={faRightFromBracket} title={"Log Out"} onClick={() => {
                    logout();
                }}/>
            </UserBarGroup>
        </div>
    );
};

const UserBarGroup = (props: { children: ReactNode }) => {
    return (
        <div className={"component--user-bar-group"}>
            {props.children}
        </div>
    );
};

const UserBarText = (props: { children: string }) => {
    return (
        <div className={"component--user-bar-text"}>
            {props.children}
        </div>
    );
};

interface IUserBarSwitchProps {
    icon: IconDefinition
    title?: string
    active?: boolean
    onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void
}

const UserBarSwitch = (props: IUserBarSwitchProps) => {
    const {setStatus} = useChatOptionsStore();

    const showHint = () => {
        if (props.title !== undefined) {
            setStatus(props.title);
        }
    };

    const hideHint = () => {
        setStatus("");
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (props.onClick !== undefined) props.onClick(event);
    };

    const classNameArray: string[] = ["component--user-bar-switch"];
    if (props.active) classNameArray.push("active");
    return (
        <div className={classNameArray.join(" ")} onClick={(event) => {
            handleClick(event);
        }} onMouseOver={() => {
            showHint();
        }} onMouseOut={() => {
            hideHint();
        }}>
            <FontAwesomeIcon icon={props.icon} title={props.title}/>
        </div>
    );
};

interface IUserBarButtonProps {
    icon: IconDefinition
    title?: string
    onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void
}

const UserBarButton = (props: IUserBarButtonProps) => {
    const {setStatus} = useChatOptionsStore();

    const showHint = () => {
        if (props.title !== undefined) {
            setStatus(props.title);
        }
    };

    const hideHint = () => {
        setStatus("");
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (props.onClick !== undefined) props.onClick(event);
    };

    const classNameArray: string[] = ["component--user-bar-button"];
    return (
        <div className={classNameArray.join(" ")} title={props.title} onClick={(event) => {
            handleClick(event);
        }} onMouseOver={() => {
            showHint();
        }} onMouseOut={() => {
            hideHint();
        }}>
            <FontAwesomeIcon icon={props.icon}/>
        </div>
    );
};

export default UserBar;
