import React, {useEffect} from 'react';
import './App.css';
import LoginForm from "./components/login-form/LoginForm";
import AuthToken from "./AuthToken";
import Layout from "./components/layout/Layout";
import {IUser} from "./DbInterfaces";
import API from "./API";
import {useUserStore} from "./stores/userStore";
import CONFIG from "./Config";

const App = () => {
    const {isUserAuthenticated, setCurrUser, setAuthentication} = useUserStore();

    const loginVerify = () => {
        if (AuthToken.exists()) {
            AuthToken.isValid()
                .then((response) => {
                    if (response) {
                        const userId: number = AuthToken.userId();
                        API.users.get.single(userId)
                            .then((subResponse) => {
                                const user: IUser = subResponse.data;
                                setCurrUser(user);
                                setAuthentication(response);
                            });
                    }
                });
        } else {
            setAuthentication(false);
        }
    }

    useEffect(() => {
        document.title = `${CONFIG.Name} ${CONFIG.Version}`;
        loginVerify();
    }, []);

    if (isUserAuthenticated) {
        return (
            <Layout/>
        );
    } else {
        return (
            <LoginForm/>
        );
    }
};

export default App;
