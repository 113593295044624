import "./status-bar.css";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";

const StatusBar = () => {
    const {status} = useChatOptionsStore();
    return (
        <div className={"component--status-bar"}>
            <span>{status}</span>
        </div>
    );
};

export default StatusBar;
