import Modal from "../components/modal/Modal";
import {useModalsStore} from "../stores/modalsStore";
import ReactDOM from "react-dom";
import {CSSProperties, useState} from "react";

interface ISaveChatModalProps {

}

interface IExportChatProps {
    title?: string
    encoding?: string
}

const exportChat = (props: IExportChatProps): string => {
    const chatBody = document.getElementsByClassName("component--chat-body")[0]!;
    const html = document.createElement('html');
    const head = document.createElement('head');
    const style = document.createElement('style');
    const body = document.createElement('body');
    const title = document.createElement('title');
    const metaCharSet = document.createElement('meta');

    title.innerHTML = props.title ?? 'Saved chat';
    metaCharSet.setAttribute('charset', props.encoding ?? "UTF-8");
    style.innerHTML = `
body { background-color: #ebe9cf; }
svg { height: 1em; padding: 0 5px; vertical-align: text-top; }
.post { margin-bottom: 10px; padding: 0 20px; }
.time { font-size: 0.8em; color: #404040; padding: 0 5px; }
.actor { font-family: sans-serif; font-weight: bold; padding: 0 5px; }
.caption { display: block; font-size: 0.8em; }
.post.post-chapter { font-size: 2em; text-align: center; }
.post.post-narration { font-size: 0.95em; font-family: sans-serif; background-color: black; color: white; padding: 5px 20px; text-align: justify; }
.post.post-narration .time { color: #B0B0B0; }
.post.post-image { text-align: center; }
.post.post-bookmark { background-color: gray; color: white; padding: 5px 20px 5px 60px; }
.post.post-action { font-family: sans-serif; font-size: 0.9em; background-color: #C0C0C0; padding: 5px 20px; }
.post.post-note { font-family: sans-serif; font-size: 0.9em; background-color: yellow; padding: 5px 20px; }
.post.post-link { font-family: sans-serif; font-size: 0.9em; background-color: #90eeff; padding: 5px 40px; }
    `;

    head.append(title);
    head.append(metaCharSet);
    head.append(style);
    html.append(head);
    html.append(body);
    body.innerHTML = chatBody.innerHTML;
    return html.outerHTML;
};

const SaveChatModal = (props: ISaveChatModalProps) => {
    const {setSaveChat} = useModalsStore();
    const [savedChatTitle, setSavedChatTitle] = useState<string>('Saved chat');

    return (
        <Modal
            id={"save-chat-modal"} title={"Salva chat"} width={"500px"}
            closeCaption={"Chiudi"} confirmCaption={"Copia"}
            onClose={() => {
                setSaveChat(false);
            }}
            onConfirm={() => {
                const content = exportChat({
                    title: savedChatTitle
                });
                navigator.clipboard.writeText(content)
                    .then(() => {
                        alert('Codice HTML copiato negli appunti.');
                    });
            }}
        >
            <div className={"modal-form-field"}>
                <label>URL del link</label>
                <input type={"text"} value={savedChatTitle} onChange={(event) => {
                    setSavedChatTitle(event.target.value);
                }}/>
            </div>
        </Modal>
    );
};

export default SaveChatModal;