import APIHelper from "./APIHelper";

const deleteChannelPost = (channelId: number, postId: number) => {
    return APIHelper.delete(`/channels/${channelId}/posts/${postId}`);
};

const getAuthRenew = () => {
    return APIHelper.get('/auth/renew');
};

const getAuthVerify = () => {
    return APIHelper.get('/auth/verify');
};

const getChannelBookmarks = (channelId: number) => {
    return APIHelper.get(`/channels/${channelId}/bookmarks`);
};

const getChannelChapters = (channelId: number) => {
    return APIHelper.get(`/channels/${channelId}/chapters`);
};

const getChannelPosts = (channelId: number, params: any) => {
    return APIHelper.get(`/channels/${channelId}/posts`, params);
};

const getSetupInstall = () => {
    return APIHelper.get(`/install`);
};

const getUser = (userId: number) => {
    return APIHelper.get(`/users/${userId}`);
};

const getUserChannels = (userId: number) => {
    return APIHelper.get(`/users/${userId}/channels`);
};

const postAuthLogin = (identifier: string, password: string) => {
    return APIHelper.post('/auth/login', {
        identifier: identifier,
        password: password
    });
};

const putChannelPost = (channelId: number, data: any) => {
    return APIHelper.post(`/channels/${channelId}/posts`, data);
};

const API = {
    auth: {
        get: {
            renew: getAuthRenew,
            verify: getAuthVerify
        },
        post: {
            login: postAuthLogin
        }
    },
    channels: {
        delete: {
            posts: deleteChannelPost
        },
        get: {
            bookmarks: getChannelBookmarks,
            chapters: getChannelChapters,
            posts: getChannelPosts
        },
        put: {
            posts: putChannelPost
        }
    },
    setup: {
        get: {
            install: getSetupInstall
        }
    },
    users: {
        get: {
            single: getUser,
            channels: getUserChannels
        }
    }
};

export default API;