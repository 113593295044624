import create from 'zustand';
import {DUser, IUser} from "../DbInterfaces";
import LSSettings from "../LSSHandler";

export interface IUserStore {
    isUserAuthenticated: boolean
    currUser?: IUser
    setAuthentication: (authentication: boolean) => void
    setCurrUser: (user: IUser) => void
    logout: () => void
}

export const useUserStore = create<IUserStore>((set) => ({
    isUserAuthenticated: false,
    setAuthentication: (authentication: boolean) => set((state) => ({
        isUserAuthenticated: authentication
    })),
    setCurrUser: (newUser: IUser) => set((state) => ({
        currUser: newUser
    })),
    logout: () => set((state) => {
        LSSettings.delete("authToken");
        return {
            isUserAuthenticated: false,
            currUser: DUser
        }
    })
}));