import "./theme-selection-modal.css";
import Modal from "../../components/modal/Modal";
import {useModalsStore} from "../../stores/modalsStore";
import {useThemeStore} from "../../stores/themeStore";
import ColorTheme, {TColorTheme} from "../../ColorTheme";

const ThemeSelectionModal = () => {
    const {setThemeSelection} = useModalsStore();
    const {setColorTheme} = useThemeStore();

    const close = () => {
        setThemeSelection(false);
    };

    const themes: TColorTheme[] = ["Light", "Ivory", "Peach", "Night", "Chocolate", "Dark"];

    return (
        <Modal id={"theme-selection-modal"} title={"Selezione del tema"} onClose={() => {
            close();
        }}>
            <div className={"grid"}>
                {themes.map((theme, index) => {
                    const classes = ColorTheme.getClasses(theme);
                    return (
                        <div className={`element ${classes.window} ${classes.text}`} key={index} onClick={() => {
                            setColorTheme(theme);
                        }}>
                            <span>Tema {theme}</span>
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
};

export default ThemeSelectionModal;
