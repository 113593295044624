import Widget from "../widget/Widget";
import {useChannelStore} from "../../stores/channelStore";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";
import {useUserStore} from "../../stores/userStore";
import AuthToken from "../../AuthToken";
import {useEffect, useState} from "react";

export const ChannelStoreDebug = () => {
    const {currChannel, lastInput, forceUpdate} = useChannelStore();
    return (
        <Widget title={"ChannelStore Debug"}>
            <strong>currChannel:</strong>
            <ul>
                <li>id: <strong>{currChannel.id}</strong></li>
                <li>name: <strong>{currChannel.name}</strong></li>
                <li>actor_name: <strong>{currChannel.actor_name}</strong></li>
                <li>actor_gender <strong>{currChannel.actor_gender}</strong></li>
                <li>last_seen_at: <strong>{currChannel.last_seen_at}</strong></li>
                <li>is_author: <strong>{currChannel.is_author ? "true" : "false"}</strong></li>
                <li>is_owner: <strong>{currChannel.is_owner ? "true" : "false"}</strong></li>
                <li>is_storyteller: <strong>{currChannel.is_storyteller ? "true" : "false"}</strong></li>
            </ul>
            <hr/>
            <ul>
                <li>lastInput: <strong>{lastInput.toString()}</strong></li>
                <li>forceUpdate: <strong>{forceUpdate ? "true" : "false"}</strong></li>
            </ul>
        </Widget>
    );
};

export const ChatOptionsStoreDebug = () => {
    const {
        chatContentFilters,
        chatQueryFilters,
        autoScroll,
        sounds,
        status,
        leftMenu,
        rightMenu
    } = useChatOptionsStore();
    return (
        <Widget title={"OptionStore Debug"}>
            <strong>chatContentFilters:</strong>
            <ul>
                <li>bookmarks: <strong>{chatContentFilters.bookmarks ? "true" : "false"}</strong></li>
                <li>ops: <strong>{chatContentFilters.ops ? "true" : "false"}</strong></li>
                <li>images: <strong>{chatContentFilters.images ? "true" : "false"}</strong></li>
                <li>links: <strong>{chatContentFilters.links ? "true" : "false"}</strong></li>
                <li>time: <strong>{chatContentFilters.time ? "true" : "false"}</strong></li>
                <li>Chapters: <strong>{chatContentFilters.Chapters ? "true" : "false"}</strong></li>
                <li>images: <strong>{chatContentFilters.images ? "true" : "false"}</strong></li>
                <li>narration: <strong>{chatContentFilters.narration ? "true" : "false"}</strong></li>
                <li>notes: <strong>{chatContentFilters.notes ? "true" : "false"}</strong></li>
                <li>system: <strong>{chatContentFilters.system ? "true" : "false"}</strong></li>
            </ul>
            <hr/>
            <strong>chatQueryFilters:</strong>
            <ul>
                <li>fetchMode: <strong>{chatQueryFilters.fetchMode}</strong></li>
            </ul>
            <hr/>
            <ul>
                <li>autoScroll: <strong>{autoScroll ? "true" : "false"}</strong></li>
                <li>sounds: <strong>{sounds ? "true" : "false"}</strong></li>
                <li>status: <strong>{status}</strong></li>
                <li>leftMenu: <strong>{leftMenu ? "true" : "false"}</strong></li>
                <li>rightMenu: <strong>{rightMenu ? "true" : "false"}</strong></li>
            </ul>
        </Widget>
    );
};

export const UserStoreDebug = () => {
    const {isUserAuthenticated, currUser} = useUserStore();

    return (
        <Widget title={"UserStore Debug"}>
            <ul>
                <li>isUserAuthenticated: <strong>{isUserAuthenticated ? "true" : "false"}</strong></li>
            </ul>
            <hr/>
            <strong>currUser:</strong>
            <ul>
                <li>id: <strong>{currUser?.id}</strong></li>
                <li>name: <strong>{currUser?.name}</strong></li>
                <li>gender: <strong>{currUser?.gender}</strong></li>
                <li>email: <strong>{currUser?.email}</strong></li>
                <li>is_admin: <strong>{currUser?.is_admin}</strong></li>
                <li>created_at: <strong>{currUser?.created_at}</strong></li>
                <li>updated_at: <strong>{currUser?.updated_at}</strong></li>
            </ul>
        </Widget>
    );
};

const AuthTokenDebug = () => {
    const [userId, setUserId] = useState(-1);
    const [userName, setUserName] = useState("");
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [expiration, setExpiration] = useState(0);
    const [isAboutToExpire, setIsAboutToExpire] = useState(false);

    const refreshState = () => {
        setUserId(AuthToken.userId());
        setUserName(AuthToken.userName());
        setIsUserAdmin(AuthToken.isUserAdmin());
        setExpiration(AuthToken.expiration());
        setIsAboutToExpire(AuthToken.isAboutToExpire());
    };

    useEffect(() => {
        refreshState();
    }, [])

    return (
        <Widget title={"AuthToken Debug"}>
            <div style={{textAlign: "right"}}>
                <button onClick={() => {
                    refreshState();
                }}>
                    Refresh
                </button>
            </div>
            <ul>
                <li>userId: <strong>{userId}</strong></li>
                <li>userName: <strong>{userName}</strong></li>
                <li>isUserAdmin: <strong>{isUserAdmin ? "true" : "false"}</strong></li>
                <li>expiration: <strong>{expiration}</strong></li>
                <li>isAboutToExpire: <strong>{isAboutToExpire ? "true" : "false"}</strong></li>
            </ul>
        </Widget>
    );
};

const Debug = () => {
    return (
        <>
            <UserStoreDebug/>
            <ChatOptionsStoreDebug/>
            <ChannelStoreDebug/>
            <AuthTokenDebug/>
        </>
    );
};

export default Debug;