import "./layout.css";
import {useEffect} from "react";
import AuthToken from "../../AuthToken";
import API from "../../API";
import CONFIG from "../../Config";
import LSSettings from "../../LSSHandler";
import UserBar from "../user-bar/UserBar";
import StatusBar from "../status-bar/StatusBar";
import ChannelSelectionModal from "../../modals/channel-selection/ChannelSelectionModal";
import {useModalsStore} from "../../stores/modalsStore";
import ThemeSelectionModal from "../../modals/theme-selection/ThemeSelectionModal";
import Channel from "../channel/Channel";
import {useChannelStore} from "../../stores/channelStore";
import {useUserStore} from "../../stores/userStore";
import SaveChatModal from "../../modals/SaveChatModal";

const Layout = () => {
    const {currUser, logout} = useUserStore();
    const {currChannel, setForceUpdate} = useChannelStore();
    const modalsStore = useModalsStore();

    let tokenRenewTimeout: NodeJS.Timeout | undefined;
    useEffect(() => {
        tokenRenewTimeout = setInterval(() => {
            if (AuthToken.isAboutToExpire()) renewAttempt();
        }, CONFIG.TokenRenewInterval);

        return () => {
            clearInterval(tokenRenewTimeout!);
        };
    }, []);

    const renewAttempt = () => {
        API.auth.get.renew()
            .then((response) => {
                const token = response.data.token;
                LSSettings.write("authToken", token);
            })
            .catch(() => {
                logout();
            })
    };

    return (
        <div className={"component--layout"}>
            <div className={"top"}>
                <UserBar/>
            </div>
            <div className={"middle"}>
                <Channel id={currChannel.id}/>
            </div>
            <div className={"bottom"}>
                <StatusBar/>
            </div>
            {modalsStore.channelSelection && (
                <ChannelSelectionModal/>
            )}
            {modalsStore.themeSelection && (
                <ThemeSelectionModal/>
            )}
            {modalsStore.saveChat && (
                <SaveChatModal/>
            )}
        </div>
    );
};

export default Layout;
