import './chat-filters.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    IconDefinition, faBookmark, faNoteSticky, faBook, faFileImage, faParagraph,
    faUpRightFromSquare, faClock, faCircleExclamation, faGear
} from "@fortawesome/free-solid-svg-icons";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";
import {useThemeStore} from "../../stores/themeStore";

type TChatFetchMode = "full-channel" | "full-chapter" | "last-posts";

export interface IChatContentFilters {
    time: boolean
    ops: boolean
    narration: boolean
    bookmarks: boolean
    notes: boolean
    images: boolean
    Chapters: boolean
    links: boolean
    system: boolean
}

export interface IChatQueryFilters {
    fetchMode: TChatFetchMode
    ChapterId: number
}

export const DChatContentFilters: IChatContentFilters = {
    time: true,
    ops: false,
    narration: true,
    bookmarks: true,
    notes: true,
    images: true,
    Chapters: true,
    links: true,
    system: true
};

export const DChatQueryFilters: IChatQueryFilters = {
    fetchMode: "last-posts",
    ChapterId: -1
};

const ChatFilters = () => {
    const {getThemeClasses} = useThemeStore();
    const {chatContentFilters, chatQueryFilters, setChatContentFilters, setChatQueryFilters} = useChatOptionsStore();

    interface IContentFilters {
        icon: IconDefinition
        active: boolean
        title: string
        onClick?: () => void
    }

    type TContentFilter =
        "time"
        | "ops"
        | "narration"
        | "bookmarks"
        | "notes"
        | "images"
        | "Chapters"
        | "links"
        | "system";
    const toggleContentFilters = (filter: TContentFilter) => {
        const newContentFilters: IChatContentFilters = chatContentFilters;
        switch (filter) {
            case "time":
                newContentFilters.time = !newContentFilters.time;
                break;
            case "ops":
                newContentFilters.ops = !newContentFilters.ops;
                break;
            case "narration":
                newContentFilters.narration = !newContentFilters.narration;
                break;
            case "bookmarks":
                newContentFilters.bookmarks = !newContentFilters.bookmarks;
                break;
            case "notes":
                newContentFilters.notes = !newContentFilters.notes;
                break;
            case "images":
                newContentFilters.images = !newContentFilters.images;
                break;
            case "Chapters":
                newContentFilters.Chapters = !newContentFilters.Chapters;
                break;
            case "links":
                newContentFilters.links = !newContentFilters.links;
                break;
            case "system":
                newContentFilters.system = !newContentFilters.system;
                break;
        }
        setChatContentFilters(newContentFilters);
    };

    const contentFilters: IContentFilters[] = [
        {
            icon: faClock, active: chatContentFilters.time, title: "Orari", onClick: () => {
                toggleContentFilters("time")
            }
        },
        {
            icon: faCircleExclamation, active: chatContentFilters.ops, title: "Operazioni", onClick: () => {
                toggleContentFilters("ops")
            }
        },
        {
            icon: faBook, active: chatContentFilters.narration, title: "Narrazione", onClick: () => {
                toggleContentFilters("narration")
            }
        },
        {
            icon: faBookmark, active: chatContentFilters.bookmarks, title: "Segnalibri", onClick: () => {
                toggleContentFilters("bookmarks")
            }
        },
        {
            icon: faNoteSticky, active: chatContentFilters.notes, title: "Note", onClick: () => {
                toggleContentFilters("notes")
            }
        },
        {
            icon: faFileImage, active: chatContentFilters.images, title: "Immagini", onClick: () => {
                toggleContentFilters("images")
            }
        },
        {
            icon: faParagraph, active: chatContentFilters.Chapters, title: "Capitoli", onClick: () => {
                toggleContentFilters("Chapters")
            }
        },
        {
            icon: faUpRightFromSquare, active: chatContentFilters.links, title: "Collegamenti", onClick: () => {
                toggleContentFilters("links")
            }
        },
        {
            icon: faGear, active: chatContentFilters.system, title: "Messaggi di sistema", onClick: () => {
                toggleContentFilters("system")
            }
        }
    ];

    const handleFetchModeChange = (newFetchMode: TChatFetchMode) => {
        const newChatQueryFilters: IChatQueryFilters = chatQueryFilters;
        newChatQueryFilters.fetchMode = newFetchMode;
        if (newFetchMode === "last-posts") newChatQueryFilters.ChapterId = -1;
        setChatQueryFilters(newChatQueryFilters);
    };

    const theme = getThemeClasses();
    return (
        <div className={`component--chat-filters ${theme.window}`}>
            <ChatFiltersGroup>
                {contentFilters.map((contentFilter, index) => {
                    return (
                        <ChatFiltersSwitch
                            key={index}
                            icon={contentFilter.icon}
                            active={contentFilter.active}
                            title={contentFilter.title}
                            onClick={contentFilter.onClick}
                        />
                    );
                })}
            </ChatFiltersGroup>
            <ChatFiltersGroup>
                <ChatFiltersSelect selected={chatQueryFilters.fetchMode} options={[
                    {
                        id: "full-channel",
                        label: "Tutto il canale"
                    },
                    {
                        id: "full-chapter",
                        label: "Tutto il capitolo"
                    },
                    {
                        id: "last-posts",
                        label: "Ultimi post"
                    },
                ]} onChange={(value) => {
                    handleFetchModeChange(value);
                }}/>
            </ChatFiltersGroup>
        </div>
    );
};

const ChatFiltersGroup = (props: { children: React.ReactNode }) => {
    return (
        <div className={"component--chat-filters-group"}>
            {props.children}
        </div>
    );
};

interface IChatFiltersSwitchProps {
    icon: IconDefinition
    active?: boolean
    title?: string
    onClick?: () => void
}

const ChatFiltersSwitch = (props: IChatFiltersSwitchProps) => {
    const {setStatus} = useChatOptionsStore();
    const handleClick = () => {
        if (props.onClick !== undefined) props.onClick();
    };

    const handleMouseOver = () => {
        if (props.title !== undefined) {
            setStatus(props.title);
        }
    };

    const handleMouseOut = () => {
        setStatus("");
    };

    const classNamesArray: string[] = ["component--chat-filters-switch"];
    if (props.active) classNamesArray.push("active");
    return (
        <div className={classNamesArray.join(" ")} title={props.title} onClick={() => {
            handleClick();
        }} onMouseOver={() => {
            handleMouseOver();
        }} onMouseOut={() => {
            handleMouseOut();
        }}>
            <FontAwesomeIcon icon={props.icon}/>
        </div>
    );
};

interface IChatFiltersSelectProps {
    options: {
        id: TChatFetchMode
        label: string
    }[]
    selected: string
    onChange?: (value: TChatFetchMode) => void
}

const ChatFiltersSelect = (props: IChatFiltersSelectProps) => {
    const handleChange = (value: TChatFetchMode) => {
        if (props.onChange !== undefined) {
            props.onChange(value);
        }
    };

    return (
        <select className={"component--chat-filters-select"} value={props.selected}
                onChange={(event) => {
                    handleChange(event.target.value as TChatFetchMode);
                }}>
            {props.options.map((option, index) => {
                return (
                    <option key={index} value={option.id}>{option.label}</option>
                );
            })}
        </select>
    );
};

export default ChatFilters;
