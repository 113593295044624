import {useRef} from "react";
import {useThemeStore} from "../../../stores/themeStore";
import ColorTheme from "../../../ColorTheme";

const Picture = (props: { onSend: (content: string) => void }) => {
    const urlRef = useRef<HTMLInputElement>(null);
    const altRef = useRef<HTMLInputElement>(null);
    const captionRef = useRef<HTMLInputElement>(null);
    const widthRef = useRef<HTMLInputElement>(null);
    const heightRef = useRef<HTMLInputElement>(null);

    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    const handleSendButtonClick = () => {
        if (props.onSend === undefined) return;
        if (urlRef === null) return;
        if (altRef === null) return;
        if (captionRef === null) return;
        if (widthRef === null) return;
        if (heightRef === null) return;

        const inputWidth: number = parseInt(widthRef!.current!.value);
        const inputHeight: number = parseInt(heightRef!.current!.value);

        const content = {
            src: urlRef!.current!.value,
            alt: altRef!.current!.value,
            caption: captionRef!.current!.value,
            width: inputWidth === 0 ? "auto" : `${inputWidth}px`,
            height: inputHeight === 0 ? "auto" : `${inputHeight}px`
        };

        props.onSend(JSON.stringify(content));
    };

    return (
        <div className={"content-form picture"}>
            <table>
                <tbody>
                <tr>
                    <td className={"column-header"}>
                        URL
                    </td>
                    <td className={"column-value"}>
                        <input ref={urlRef} defaultValue={"https://"} style={{width: "100%"}}/>
                    </td>
                </tr>
                <tr>
                    <td className={"column-header"}>
                        Testo alternativo
                    </td>
                    <td className={"column-value"}>
                        <input ref={altRef} defaultValue={"Immagine"} style={{width: "100%"}}/>
                    </td>
                </tr>
                <tr>
                    <td className={"column-header"}>
                        Didascalia
                    </td>
                    <td className={"column-value"}>
                        <input ref={captionRef} defaultValue={""} style={{width: "100%"}}/>
                    </td>
                </tr>
                <tr>
                    <td className={"column-header"}>
                        Larghezza (px)
                    </td>
                    <td className={"column-value"}>
                        <input ref={widthRef} type={"number"} defaultValue={0}/>
                        <span>Lascia "0" per far determinare automaticamente la larghezza.</span>
                    </td>
                </tr>
                <tr>
                    <td className={"column-header"}>
                        Altezza (px)
                    </td>
                    <td className={"column-value"}>
                        <input ref={heightRef} type={"number"} defaultValue={0}/>
                        <span>Lascia "0" per far determinare automaticamente l'altezza.</span>
                    </td>
                </tr>
                </tbody>
            </table>
            <button className={`command-button ${theme.window}`} style={{float: "right"}} onClick={() => {
                handleSendButtonClick();
            }}>
                Invia
            </button>
        </div>
    );
};

export default Picture;