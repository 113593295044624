import React, {useState} from "react";
import './login-form.css';
import LSSettings from "../../LSSHandler";
import ColorTheme from "../../ColorTheme";
import API from "../../API";
import {IUser} from "../../DbInterfaces";
import {useUserStore} from "../../stores/userStore";
import {useThemeStore} from "../../stores/themeStore";

const LoginForm = () => {
    const {setCurrUser, setAuthentication} = useUserStore();
    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    const [checkInProgress, setCheckInProgress] = useState<boolean>(false);
    const [identifier, setIdentifier] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [messages, setMessages] = useState<string[]>([]);

    const loginAttempt = () => {
        setCheckInProgress(true);
        setMessages([]);

        API.auth.post.login(identifier, password)
            .then((response) => {
                const token = response.data.token;
                const user: IUser = response.data.user;
                LSSettings.write("authToken", token);
                setCurrUser(user);
                setAuthentication(true);
            })
            .catch((error) => {
                addMessage(error.response.data.message);
                setCheckInProgress(false);
            });
    };

    const addMessage = (message: string) => {
        setMessages([...messages]);
    };

    const handleInputFieldChange = (event: React.ChangeEvent<HTMLInputElement>, field: "identifier" | "password") => {
        if (field === "identifier") setIdentifier(event.target.value);
        if (field === "password") setPassword(event.target.value);
    };

    return (
        <div className={"loginContainer"}>
            <div className={"loginIncipit"}>
                <h1>Sourire 3.5</h1>
                <h4>Dieci anni di storie.</h4>
                <h4>Centinaia di vite immaginarie.</h4>
            </div>
            <div className={`loginForm ${theme.window}`}>
                {!checkInProgress && (
                    <form>
                        <p>Inserisci le tue credenziali di accesso.</p>
                        <hr/>
                        <span className={`loginInputFieldLabel ${theme.title}`}>Nome utente / Indirizzo e-mail</span>
                        <input
                            type={"email"} id={"loginIdentifier"} name={"loginIdentifier"}
                            className={`loginInputField ${theme.text}`}
                            placeholder={"my.email@domain.net"} autoComplete={"username"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputFieldChange(event, 'identifier');
                            }}
                            value={identifier}
                        />
                        <hr/>
                        <span className={`loginInputFieldLabel ${theme.title}`}>Password</span>
                        <input
                            type={"password"} id={"loginPassword"} name={"loginPassword"}
                            className={`loginInputField ${theme.text}`} placeholder={"mypassword"}
                            autoComplete={"current-password"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputFieldChange(event, 'password');
                            }}
                            value={password}
                        />
                        <hr/>
                        {messages.length !== 0 && (
                            <div className={"loginMessagesContainer"}>
                                {messages.map((message, index) => {
                                    return (
                                        <span className={"loginMessage"} key={index}>{message}</span>);
                                })}
                            </div>
                        )}
                        <input type={"button"} id={"loginButton"} name={"loginButton"} value={"Accedi"}
                               className={"loginInputButton"} onClick={() => {
                            loginAttempt();
                        }}/>
                    </form>
                )}
                {checkInProgress && (
                    <>
                        Autenticazione in corso...
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginForm;
