import create from 'zustand';
import {
    DChatContentFilters, DChatQueryFilters, IChatContentFilters, IChatQueryFilters
} from "../components/chat-filters/ChatFilters";
import LSSettings from "../LSSHandler";

export interface IChatOptionsStore {
    chatContentFilters: IChatContentFilters
    chatQueryFilters: IChatQueryFilters
    autoScroll: boolean
    sounds: boolean
    status: string
    leftMenu: boolean
    rightMenu: boolean
    setChatContentFilters: (newChatContentFilters: IChatContentFilters) => void
    setChatQueryFilters: (newChatContentFilters: IChatQueryFilters) => void
    setAutoScroll: (newAutoScroll: boolean) => void
    setSounds: (newSounds: boolean) => void
    setStatus: (message: string) => void
    setLeftMenu: (enabled: boolean) => void
    setRightMenu: (enabled: boolean) => void
}

export const useChatOptionsStore = create<IChatOptionsStore>((set) => ({
    chatContentFilters: LSSettings.read("chatContentFilters", DChatContentFilters),
    chatQueryFilters: LSSettings.read("chatQueryFilters", DChatQueryFilters),
    autoScroll: LSSettings.read("autoScroll", true),
    sounds: LSSettings.read("sounds", true),
    status: "Sourire 3.5",
    leftMenu: LSSettings.read("leftMenu", false),
    rightMenu: LSSettings.read("rightMenu", false),
    setChatContentFilters: (newChatContentFilters: IChatContentFilters) => set((state) => {
        LSSettings.write("chatContentFilters", newChatContentFilters);
        return {
            chatContentFilters: newChatContentFilters
        }
    }),
    setChatQueryFilters: (newChatQueryFilters: IChatQueryFilters) => set((state) => {
        LSSettings.write("chatQueryFilters", newChatQueryFilters);
        return {
            chatQueryFilters: newChatQueryFilters
        }
    }),
    setAutoScroll: (newAutoScroll: boolean) => set((state) => {
        LSSettings.write("autoScroll", newAutoScroll);
        return {
            autoScroll: newAutoScroll
        };
    }),
    setSounds: (newSounds: boolean) => set((state) => {
        LSSettings.write("sounds", newSounds);
        return {
            sounds: newSounds
        };
    }),
    setStatus: (message: string) => set((state) => {
        return {
            status: message === "" ? "Sourire 3.5" : message
        };
    }),
    setLeftMenu: (enabled: boolean) => set((state) => {
        LSSettings.write("leftMenu", enabled);
        return {
            leftMenu: enabled
        };
    }),
    setRightMenu: (enabled: boolean) => set((state) => {
        LSSettings.write("rightMenu", enabled);
        return {
            rightMenu: enabled
        };
    })
}));