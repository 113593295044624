import {IPost} from "../../DbInterfaces";
import './chat-post.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    IconDefinition,
    faBookmark,
    faParagraph,
    faMars,
    faVenus,
    faAnkh,
    faEdit,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import API from "../../API";
import {useChannelStore} from "../../stores/channelStore";
import {useUserStore} from "../../stores/userStore";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";

interface IProps {
    post: IPost
}

interface IDateTime {
    date: string,
    time: string
}

export interface IPostImage {
    src: string,
    alt: string,
    caption?: string,
    width?: string,
    height?: string
}

export interface IPostLink {
    href: string,
    text?: string,
    caption?: string
}

const getDateTime = (datetime: string): IDateTime => {
    const splitted: string[] = datetime.split("T");
    const fulltime: string = splitted[1].split(".")[0];
    const ftsplitted: string[] = fulltime.split(":");
    const Hi: string = ftsplitted[0] + ":" + ftsplitted[1];
    return {
        date: splitted[0],
        time: Hi
    };
};

const ChatPost = (props: IProps) => {
    const {currChannel, setLastInput, setForceUpdate} = useChannelStore();
    const {currUser} = useUserStore();
    const {chatContentFilters} = useChatOptionsStore();

    const handleEditPost = (id: number) => {
        alert(`ID ${id}, ma la funzione di MODIFICA è al momento disabilitata.`);
    };

    const handleDeletePost = (id: number) => {
        const checkNumber: string = Math.floor(Math.random() * (999999 - 111111) + 111111).toString();
        const answer: string | null = prompt(`Digita il codice di sicurezza ${checkNumber} per eliminare il post.`);

        if ((answer === null) || (answer !== checkNumber)) {
            alert("Il post NON è stato eliminato.");
        } else {
            API.channels.delete.posts(currChannel.id, id)
                .then(() => {
                    alert("Il post è stato eliminato con successo.");
                    setLastInput(new Date());
                    setForceUpdate(true);
                });
        }
    };

    const postActionIcons = (id: number) => {
        return (
            <>
                <FontAwesomeIcon
                    className={"editIcon"} icon={faEdit} title={"Modifica"}
                    onClick={() => {
                        handleEditPost(id)
                    }}
                />
                <FontAwesomeIcon
                    className={"deleteIcon"} icon={faTrashAlt} title={"Elimina"}
                    onClick={() => {
                        handleDeletePost(id)
                    }}
                />
            </>
        );
    };

    const dialogPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const dt: IDateTime = getDateTime(post.created_at);
        let icon: IconDefinition = faAnkh;
        let iconClasses: string[] = ["gender-icon"];

        if (post.actor_gender === "M") {
            icon = faMars;
            iconClasses.push("male");
        }
        if (post.actor_gender === "F") {
            icon = faVenus;
            iconClasses.push("female");
        }

        return (
            <div className={"post post-dialog"}>
                {chatContentFilters.time && (<span className={"time"}>{dt.time}</span>)}
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
                <span className={"actor"}>{post.actor_name}</span>
                <FontAwesomeIcon className={iconClasses.join(" ")} icon={icon}/>
                <span className={"content"}>{post.content}</span>
            </div>
        );
    };

    const actionPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const dt: IDateTime = getDateTime(post.created_at);
        return (
            <div className={"post post-action"}>
                {chatContentFilters.time && (<span className={"time"}>{dt.time}</span>)}
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
                <span className={"actor"}>{post.actor_name}</span>
                <span className={"content"}>{post.content}</span>
            </div>
        );
    };

    const narrationPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const dt: IDateTime = getDateTime(post.created_at);
        return (
            <div className={"post post-narration"}>
                {chatContentFilters.time && (<span className={"time"}>{dt.time}</span>)}
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
                <span className={"content"}>{post.content}</span>
            </div>
        );
    };

    const bookmarkPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;

        return (
            <div className={"post post-bookmark"}>
                <FontAwesomeIcon className={"icon"} icon={faBookmark}/>
                <span className={"content"}>{post.content}</span>
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
            </div>
        );
    };

    const chapterPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;

        return (
            <div className={"post post-chapter"}>
                <FontAwesomeIcon className={"icon"} icon={faParagraph}/>
                <span className={"content"}>{post.content}</span>
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
            </div>
        );
    };

    const imagePost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const imageProps: IPostImage = JSON.parse(post.content);

        const style = {
            width: imageProps.width ?? "auto",
            height: imageProps.height ?? "auto"
        };

        return (
            <div className={"post post-image"}>
                <figure>
                    <img alt={imageProps.alt} className="image" src={imageProps.src} style={style}/>
                    {imageProps.caption !== undefined && (
                        <figcaption className="caption">
                            {imageProps.caption}
                        </figcaption>
                    )}
                </figure>
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
            </div>
        );
    };

    const notePost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const dt: IDateTime = getDateTime(post.created_at);

        return (
            <div className={"post post-note"}>
                {chatContentFilters.time && (<span className={"time"}>{dt.time}</span>)}
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
                <span className={"actor"}>{post.actor_name}</span>
                <span className={"content"}>{post.content}</span>
            </div>
        );
    };

    const systemPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const dt: IDateTime = getDateTime(post.created_at);

        return (
            <div className={"post post-system"}>
                {chatContentFilters.time && (<span className={"time"}>{dt.time}</span>)}
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
                <span className={"content"}>{post.content}</span>
            </div>
        );
    };

    const linkPost = (post: IPost) => {
        const canEdit: boolean = (post.user_id === currUser!.id) || currUser!.is_admin || currChannel.is_owner;
        const linkProps: IPostLink = JSON.parse(post.content);

        return (
            <div className={"post post-link"}>
                <a className={"link"} href={linkProps.href} target={"_blank"} rel={"noreferrer"}>
                    {linkProps.text !== undefined ? linkProps.text : linkProps.href}
                </a>
                {(
                    <span className={"caption"}>
                        {linkProps.caption}
                    </span>
                )}
                {canEdit && chatContentFilters.ops ? postActionIcons(post.id!) : null}
            </div>
        );
    };

    switch (props.post.content_type) {
        case "narration":
            return narrationPost(props.post);
        case "action":
            return actionPost(props.post);
        case "bookmark":
            return bookmarkPost(props.post);
        case "chapter_break":
            return chapterPost(props.post);
        case "image":
            return imagePost(props.post);
        case "note":
            return notePost(props.post);
        case "link":
            return linkPost(props.post);
        case "system":
            return systemPost(props.post);
        default:
            return dialogPost(props.post);
    }
};

export default ChatPost;
