import Model, {IModelProps} from "./Model";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";

interface IPostTypeProps extends IModelProps {
    id: string
    name: string
    icon: IconDefinition
}

class PostType extends Model implements IPostTypeProps {
    id: string;
    name: string;
    icon: IconDefinition;

    constructor(props?: IPostTypeProps) {
        super(props);

        if (props !== undefined) {
            this.id = props.id;
            this.name = props.name;
            this.icon = props.icon;
            return;
        }

        this.id = "";
        this.name = "";
        this.icon = faEnvelope;
    }
}

export default PostType;