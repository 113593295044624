import './chat.css';
import ChatFilters from "../chat-filters/ChatFilters";
import ChatBody from "../chat-body/ChatBody";
import ChatCommands from "../chat-commands/ChatCommands";
import {useChannelStore} from "../../stores/channelStore";
import {IPost} from "../../DbInterfaces";
import API from "../../API";
import LSSettings from "../../LSSHandler";

export const forceScroll = (bottom: boolean = true) => {
    const chatBody: HTMLDivElement = document.getElementsByClassName("component--chat-body")[0] as HTMLDivElement;
    const scrollOptions: ScrollToOptions = {
        top: (bottom ? chatBody.scrollHeight : 0),
        behavior: "auto"
    };
    chatBody.scrollTo(scrollOptions);
};

export const playSound = () => {
    const audio: HTMLAudioElement = document.getElementsByClassName('notification-sound')[0] as HTMLAudioElement;
    audio.volume = 0.1;
    audio.play()
        .then(() => {
            // nothing
        })
        .catch((response) => {
            // nothing too
        });
}

const Chat = (props: { posts: IPost[], onPostSubmit: () => void }) => {
    const {setLastInput} = useChannelStore();

    const handlePostSubmit = (post: IPost) => {
        API.channels.put.posts(post.channel_id, post)
            .then((response) => {
                setLastInput(new Date());
                props.onPostSubmit();
            })
            .catch((error) => {
                LSSettings.write("contentBackup", post.content);
                alert("Qualcosa non funziona con l'invio del post. Il contenuto è stato salvato.");
            });
    };

    return (
        <div className={"component--chat"}>
            <ChatFilters/>
            <ChatBody posts={props.posts} onChatUpdated={(byOthers: boolean, firstTime: boolean) => {
                return;
            }}/>
            <ChatCommands
                onSend={(post: IPost) => {
                    handlePostSubmit(post);
                }}
            />
            <audio controls className={"notification-sound"}>
                <source src={process.env.PUBLIC_URL + '/tinkerbell.wav'} type="audio/wav"/>
            </audio>
        </div>
    );
};

export default Chat;
