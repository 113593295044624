import create from 'zustand';

export interface IModalsStore {
    channelSelection: boolean
    themeSelection: boolean
    postImage: boolean
    postLink: boolean
    postBookmark: boolean
    postChapter: boolean
    saveChat: boolean
    setChannelSelection: (show: boolean) => void
    setThemeSelection: (show: boolean) => void
    setPostImage: (show: boolean) => void
    setPostLink: (show: boolean) => void
    setPostBookmark: (show: boolean) => void
    setPostChapter: (show: boolean) => void
    setSaveChat: (show: boolean) => void
}

export const useModalsStore = create<IModalsStore>((set) => ({
    channelSelection: false,
    themeSelection: false,
    postImage: false,
    postLink: false,
    postBookmark: false,
    postChapter: false,
    saveChat: false,
    setChannelSelection: (show: boolean) => set((state) => ({
        channelSelection: show
    })),
    setThemeSelection: (show: boolean) => set((state) => ({
        themeSelection: show
    })),
    setPostImage: (show: boolean) => set((state) => ({
        postImage: show
    })),
    setPostLink: (show: boolean) => set((state) => ({
        postLink: show
    })),
    setPostBookmark: (show: boolean) => set((state) => ({
        postBookmark: show
    })),
    setPostChapter: (show: boolean) => set((state) => ({
        postChapter: show
    })),
    setSaveChat: (show: boolean) => set((state) => ({
        saveChat: show
    }))
}));
