import create from 'zustand';
import LSSettings from "../LSSHandler";

export interface IChannelInfo {
    id: number
    name: string
    is_author: boolean
    is_storyteller: boolean
    is_owner: boolean
    actor_name: string
    actor_gender: string
    last_seen_at?: string
}

export const DChannel: IChannelInfo = {
    id: 0,
    name: "Global",
    is_author: false,
    is_storyteller: false,
    is_owner: false,
    actor_name: "Unknown",
    actor_gender: "N"
}

export interface IChannelStore {
    currChannel: IChannelInfo
    lastInput: Date
    forceUpdate: boolean
    setCurrChannel: (newCurrChannel: IChannelInfo) => void
    setLastInput: (newLastInput: Date) => void
    setForceUpdate: (newForceUpdate: boolean) => void
}

export const useChannelStore = create<IChannelStore>((set) => ({
    currChannel: LSSettings.read("currChannel", DChannel),
    lastInput: new Date(),
    forceUpdate: false,
    setCurrChannel: (newCurrChannel: IChannelInfo) => set((state) => {
        LSSettings.write("currChannel", newCurrChannel);
        return {
            currChannel: newCurrChannel
        };
    }),
    setLastInput: (newLastInput: Date) => set((state) => ({
        lastInput: newLastInput
    })),
    setForceUpdate: (newForceUpdate: boolean) => set((state) => ({
        forceUpdate: newForceUpdate
    }))
}));