import {useThemeStore} from "../../../stores/themeStore";
import ColorTheme from "../../../ColorTheme";
import {useRef} from "react";

const TitleOnly = (props: { onSend: (content: string) => void }) => {
    const titleRef = useRef<HTMLInputElement>(null);

    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    return (
        <div className={"content-form title-only"}>
            <table>
                <tbody>
                <tr>
                    <td className={"column-header"}>
                        Titolo
                    </td>
                    <td className={"column-value"}>
                        <input ref={titleRef} style={{width: "100%"}}/>
                    </td>
                </tr>
                </tbody>
            </table>
            <button className={`command-button ${theme.window}`} style={{float: "right"}} onClick={() => {
                if (props.onSend === undefined) return;
                if (titleRef === null) return;
                props.onSend(titleRef!.current!.value);
            }}>
                Invia
            </button>
        </div>
    );
};

export default TitleOnly;