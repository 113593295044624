import './channel.css';
import Chat, {forceScroll, playSound} from "../chat/Chat";
import {useThemeStore} from "../../stores/themeStore";
import {CSSProperties, useEffect, useState} from "react";
import {IPost, IChapter} from "../../DbInterfaces";
import API from "../../API";
import CONFIG from "../../Config";
import {useChatOptionsStore} from "../../stores/chatOptionsStore";
import {useChannelStore} from "../../stores/channelStore";
import Debug from "../debug/Debug";
import Widget from "../widget/Widget";

const Channel = (props: { id: number }) => {
    // ----- stores
    const {chatQueryFilters, autoScroll, sounds, leftMenu, rightMenu} = useChatOptionsStore();
    const {getThemeClasses} = useThemeStore();
    const {forceUpdate, setForceUpdate} = useChannelStore();

    // ----- state
    const [posts, setPosts] = useState<IPost[]>([]);
    const [chapters, setChapters] = useState<IChapter[]>([]);

    // ----- other vars
    const theme = getThemeClasses();

    const fetchPosts = () => {
        API.channels.get.posts(props.id, {
            fetch_mode: chatQueryFilters.fetchMode,
            chapter_id: chatQueryFilters.ChapterId
        })
            .then((response) => {
                const newPosts: IPost[] = response.data;
                setPosts(newPosts);
            })
    };

    const fetchChapters = () => {
        API.channels.get.chapters(props.id)
            .then((response) => {
                const newChapters: IChapter[] = response.data;
                setChapters(newChapters);
            });
    };

    const handlePostSubmit = () => {
        fetchPosts();
    };

    useEffect(() => {
        fetchPosts();
        fetchChapters();

        const interval = setInterval(() => {
            fetchPosts();
            fetchChapters();
        }, CONFIG.ChatUpdateInterval);

        return () => {
            clearInterval(interval);
        };
    }, [props.id, chatQueryFilters.fetchMode, chatQueryFilters.ChapterId]);

    useEffect(() => {
        if (autoScroll) forceScroll();
        if (sounds) playSound();
    }, [posts.length]);

    useEffect(() => {
        if (forceUpdate) {
            setForceUpdate(false);
            fetchPosts();
            fetchChapters();
        }
    }, [forceUpdate]);

    return (
        <div className={"component--channel"}>
            {leftMenu && (
                <div className={`left ${theme.window}`}>
                    <ChaptersList chapters={chapters}/>
                </div>
            )}
            <div className={"center"}>
                <Chat posts={posts} onPostSubmit={() => {
                    handlePostSubmit();
                }}/>
            </div>
            {rightMenu && (
                <div className={`right ${theme.window}`}>
                    <Debug/>
                </div>
            )}
        </div>
    );
};

interface IChaptersListProps {
    chapters: IChapter[]
}

export const ChaptersList = (props: IChaptersListProps) => {
    const {chatQueryFilters, setChatQueryFilters} = useChatOptionsStore();

    return (
        <Widget title={"Capitoli"} show={true}>
            {props.chapters.length <= 0 && (
                <p>Nessun capitolo disponibile.</p>
            )}
            {props.chapters.length > 0 && (
                <ul>
                    {props.chapters.map((Chapter) => {
                        const liStyle: CSSProperties = {
                            fontWeight: Chapter.id === chatQueryFilters.ChapterId ? "bold" : "normal",
                            filter: Chapter.id === chatQueryFilters.ChapterId ? "invert(0%)" : "invert(25%)",
                            paddingBottom: "5px",
                            cursor: "pointer"
                        };
                        return (
                            <li key={Chapter.id} style={liStyle} onClick={() => {
                                setChatQueryFilters({
                                    fetchMode: "full-chapter",
                                    ChapterId: Chapter.id
                                });
                            }}>
                                {Chapter.title}
                            </li>
                        );
                    })}
                </ul>
            )}
        </Widget>
    );
};

export default Channel;
