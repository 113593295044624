import {useRef} from "react";
import {useThemeStore} from "../../../stores/themeStore";
import ColorTheme from "../../../ColorTheme";

const Link = (props: { onSend: (content: string) => void }) => {
    const urlRef = useRef<HTMLInputElement>(null);
    const titleRef = useRef<HTMLInputElement>(null);
    const captionRef = useRef<HTMLInputElement>(null);

    const {colorTheme} = useThemeStore();
    const theme = ColorTheme.getClasses(colorTheme);

    const handleSendButtonClick = () => {
        if (props.onSend === undefined) return;
        if (urlRef === null) return;
        if (titleRef === null) return;
        if (captionRef === null) return;

        const content = {
            href: urlRef!.current!.value,
            text: titleRef!.current!.value,
            caption: captionRef!.current!.value
        };

        props.onSend(JSON.stringify(content));
    };

    return (
        <div className={"content-form picture"}>
            <table>
                <tbody>
                <tr>
                    <td className={"column-header"}>
                        URL
                    </td>
                    <td className={"column-value"}>
                        <input ref={urlRef} defaultValue={"https://"} style={{width: "100%"}}/>
                    </td>
                </tr>
                <tr>
                    <td className={"column-header"}>
                        Titolo
                    </td>
                    <td className={"column-value"}>
                        <input ref={titleRef} defaultValue={"Collegamento ipertestuale"} style={{width: "100%"}}/>
                    </td>
                </tr>
                <tr>
                    <td className={"column-header"}>
                        Didascalia
                    </td>
                    <td className={"column-value"}>
                        <input ref={captionRef} defaultValue={""} style={{width: "100%"}}/>
                    </td>
                </tr>
                </tbody>
            </table>
            <button className={`command-button ${theme.window}`} style={{float: "right"}} onClick={() => {
                handleSendButtonClick();
            }}>
                Invia
            </button>
        </div>
    );
};

export default Link;