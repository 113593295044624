import Widget from "./components/widget/Widget";

export type TLSSetting =
    "authToken"
    | "colorTheme"
    | "chatFilters"
    | "chatContentFilters"
    | "chatQueryFilters"
    | "currChannel"
    | "autoScroll"
    | "sounds"
    | "messageCache"
    | "leftMenu"
    | "rightMenu"
    | "contentBackup";

class LSSettings {

    static write(setting: TLSSetting, value: any) {
        if (value === null) {
            return;
        }

        const valueType = typeof value;
        if (valueType === "number" || valueType === "boolean" || valueType === "string") {
            localStorage.setItem(setting, value);
            return;
        }

        if (valueType === "object") {
            localStorage.setItem(setting, JSON.stringify(value));
            return;
        }
    }

    static read(setting: TLSSetting, defaultValue: any) {
        let result: any | undefined;
        let value: any | null = localStorage.getItem(setting);

        if (value === null) {
            result = defaultValue;
        } else {
            const valueType = typeof value;
            if (valueType === "number" || valueType === "boolean" || valueType === "object") {
                result = value;
            }

            if (typeof value === "string") {
                if (value === "true" || value === "1") result = true;
                else if (value === "false" || value === "0") result = false;
                else {
                    let parsed: object | undefined;
                    try {
                        parsed = JSON.parse(value);
                        result = parsed;
                    } catch (error) {
                        result = value;
                    }
                }
            }
        }

        return result;
    }

    static delete(setting: TLSSetting) {
        localStorage.removeItem(setting);
    }

    static clear() {
        localStorage.clear();
    }

}

export default LSSettings;
