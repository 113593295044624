export interface IModelProps {
    created_at?: Date
    updated_at?: Date
    deleted_at?: Date
}

class Model implements IModelProps {
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;

    constructor(props?: IModelProps) {
        if (props !== undefined) {
            this.created_at = props.created_at;
            this.updated_at = props.updated_at;
            this.deleted_at = props.deleted_at;
            return;
        }
    }

    hasNeverBeenUpdated = (): boolean => {
        if (this.created_at === undefined) return false;
        if (this.updated_at === undefined) return false;
        return this.created_at?.getTime() === this.updated_at?.getTime();
    };

    hasBeenDeleted = (): boolean => {
        return this.deleted_at !== undefined;
    };
}

export default Model;